import type { BaseData } from 'egenie-common';
import { request } from 'egenie-utils';
import React from 'react';

let i = 0;

export function stockOutDetailDeliver() {
  return {
    tab: {
      name: '送货单',
      value: 'deliver',
    },
    grid: {
      columns: [
        {
          name: '快递单号',
          key: 'wayBillCode',
          width: 300,
        },
        {
          name: '快递公司',
          key: 'courierName',
          width: 300,
        },
        {
          name: '数量',
          key: 'number',
          width: 150,
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      rows: [],
      primaryKeyField: '_id',
      sortByLocal: false,
      showCheckBox: false,
      showPager: false,
    },
    api: {
      onQuery: ({
        pid,
        cursorRow,
      }) => {
        return request<BaseData<any[]>>({
          method: 'post',
          url: '/api/cloud/wms/rest/bill/other/query/send/goods/info',
          data: { wmsOtherStockOrderId: pid },
          headers: { warehouseId: cursorRow?.warehouseId || '' },
        })
          .then((info) => ({
            ...info,
            data: (info.data || []).map((item) => ({
              ...item,
              _id: i++,
            })),
          }));
      },
    },
  };
}
