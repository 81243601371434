import { Input, message, Modal, Select, Space } from 'antd';
import moment from 'moment';
import { request, toFixed } from 'egenie-common';
import { Permission, TimeStampFormatter } from 'egenie-utils';
import _ from 'lodash';
import { Observer } from 'mobx-react';
import React from 'react';
import type { StockMainItem } from '../stockIn/types';
import type { Store } from './index';

export function mainTableColumns(context: Store) {
  return [
    {
      name: '操作',
      key: 'operate',
      width: 150,
      formatter: ({ row }: { row: StockMainItem; }) => {
        const showEdit = [
          row.approveState === 1,
          !row.wmsPickingOrderNo,
          row.otherStockOrderType !== 33,
          row.otherStockOrderType !== 30,
          !(row.otherStockOrderType === 7 && row.sourceType === 12),
          !(row.otherStockOrderType === 6 && row.sourceType === 19),
          !(row.otherStockOrderType === 39 && row.sourceType === 10),
        ].every(Boolean);

        const showGenerateRefundOrder = [
          row.approveState === 2,
          row.otherStockOrderType === 33,
          row.refoundState === 0,
        ].every(Boolean);

        return (
          <Space size="small">
            {
              showEdit ? (
                <Permission permissionId="60013_44">
                  <a
                    onClick={(event) => {
                      event.stopPropagation();
                      context.openEditModal(row);
                    }}
                    type="link"
                  >
                    编辑
                  </a>
                </Permission>
              ) : null
            }
            {
              showGenerateRefundOrder ? (
                <Permission permissionId="60013_5024">
                  <a
                    onClick={(event) => {
                      event.stopPropagation();
                      Modal.confirm({
                        content: '确认生成退货单吗?',
                        onOk: () => request({
                          url: '/api/cloud/inout/stock/order/pushRefundOrder',
                          method: 'post',
                          data: { otherStockOrderId: row.id },
                        })
                          .then(() => {
                            context.programme.gridModel.onQuery();
                            message.success('生成退货单成功');
                          }),
                      });
                    }}
                    type="link"
                  >
                    生成退货单
                  </a>
                </Permission>
              ) : null
            }
          </Space>
        );
      },
    },
    {
      name: '审核状态',
      key: 'approveStateStr',
      width: 80,
    },
    {
      name: '单据状态',
      key: 'otherStockOrderStateStr',
      width: 80,
    },
    {
      name: '单据编号',
      key: 'wmsOtherStockOrderNo',
      width: 200,
    },
    {
      name: '波次号',
      key: 'wmsPickingOrderNo',
      width: 200,
    },
    {
      name: '来源单据号',
      key: 'sourceNo',
      width: 200,
    },
    {
      name: '单据类型',
      key: 'otherStockOrderTypeStr',
      width: 100,
    },
    {
      name: '审核人',
      key: 'approvedUserName',
      width: 150,
    },
    {
      name: '仓库',
      key: 'warehouseName',
      width: 150,
    },
    {
      name: '货主',
      key: 'ownerName',
      width: 150,
    },
    {
      name: '供应商',
      key: 'vendorNo',
      width: 150,
    },
    {
      name: '制单日期',
      key: 'otherStockOrderDateStr',
      width: 150,
      formatter: ({ row }) => (
        <span>
          {row.otherStockOrderDateStr ? moment(row.otherStockOrderDateStr).format('YYYY-MM-DD') : ''}
        </span>
      ),
    },
    {
      name: '审核时间',
      key: 'approvedTime',
      width: 150,
      formatter: ({ row }) => <TimeStampFormatter value={row.approvedTime}/>,
    },
    {
      name: '创建时间',
      key: 'createTime',
      width: 150,
      formatter: ({ row }) => <TimeStampFormatter value={row.createTime}/>,
    },
    {
      name: '创建人',
      key: 'createUser',
      width: 100,
    },
    {
      name: '备注',
      key: 'note',
      width: 200,
      formatter: ({
        rowIdx,
        row,
      }: { rowIdx: number; row: StockMainItem; }) => {
        return (
          <Observer>
            {
              () => (
                <Input
                  onBlur={() => {
                    request({
                      url: '/api/cloud/wms/rest/bill/other/updateNoteByStockId',
                      method: 'post',
                      data: {
                        otherStockOrderId: row.id,
                        newNote: context.gridModel.gridModel.rows[rowIdx].note,
                      },
                    })
                      .then(() => {
                        message.success({
                          key: '备注修改成功',
                          content: '备注修改成功',
                        });
                      });
                  }}
                  onChange={(event) => context.gridModel.gridModel.rows[rowIdx].note = event.target.value}
                  onClick={(event) => event.stopPropagation()}
                  size="small"
                  value={context.gridModel.gridModel.rows[rowIdx].note}
                />
              )
            }
          </Observer>
        );
      },
    },
    {
      key: 'totalNumber',
      name: '总数量',
      width: 80,
    },

    {
      key: 'totalPrice',
      name: '总金额',
      width: 80,
      formatter: ({ row }: { row: StockMainItem; }) => {
        return (
          <span>
            {toFixed(row.totalPrice, 2)}
          </span>
        );
      },
    },
    {
      key: 'courierId',
      name: '快递',
      width: 150,
      formatter: ({
        rowIdx,
        row,
      }: { rowIdx: number; row: StockMainItem; }) => {
        return (
          <Observer>
            {
              () => (
                <Select
                  onBlur={() => {
                    request({
                      url: '/api/cloud/wms/rest/recipientInfo/update',
                      method: 'post',
                      data: {
                        sourceId: row.id,
                        sourceType: 1,
                        expressCompany: context.gridModel.gridModel.rows[rowIdx].courierId,
                        courierId: context.gridModel.gridModel.rows[rowIdx].courierId,
                        id: 0,
                        recipientName: null,
                        mobilePhone: null,
                        provinceId: null,
                        cityId: null,
                        districtId: null,
                        detailAddress: null,
                      },
                    })
                      .then(() => {
                        message.success({
                          key: '快递修改成功',
                          content: '快递修改成功',
                        });
                      });
                  }}
                  onChange={(value) => context.gridModel.gridModel.rows[rowIdx].courierId = value}
                  onClick={(event) => event.stopPropagation()}
                  optionFilterProp="label"
                  options={context.programme.filterItems.dict.courier || []}
                  showSearch
                  size="small"
                  style={{ width: '100%' }}
                  value={_.toString(context.gridModel.gridModel.rows[rowIdx].courierId) || undefined}
                />
              )
            }
          </Observer>
        );
      },
    },
    {
      key: 'refoundState',
      name: '退货单状态',
      width: 100,
      formatter: ({ row }: { row: StockMainItem; }) => {
        return (
          <span>
            {row.refoundState ? '已生成' : '未生成'}
          </span>
        );
      },
    },
    {
      key: 'supplierTypeDesc',
      name: '供应商类型',
      width: 100,
    },
    {
      name: '打包时间',
      key: 'printParcelTimeStr',
      width: 150,
      formatter: ({ row }) => <TimeStampFormatter value={row.printParcelTimeStr}/>,
    },
    {
      key: 'weight',
      name: '重量',
      width: 80,
    },
    {
      key: 'wmsPickingOrderNum',
      name: '波次数量',
      width: 80,
    },
  ].map((info) => ({
    resizable: true,
    sortable: false,
    ...info,
  }));
}
