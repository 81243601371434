import type { FormInstance } from 'antd';
import { message, Button, Space, Select, Input, Form, Row, Col } from 'antd';
import type { BaseData, ValueAndLabelData } from 'egenie-utils';
import { request } from 'egenie-utils';
import _ from 'lodash';
import { action, observable, set } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { getAddress } from '../../utils';
import styles from './index.less';

interface PersonItem {
  'id': string;
  'sourceId': string;
  'sourceType': number;
  'recipientName': string;
  'recipientProvince': string;
  'recipientCity': string;
  'recipientDistrict': string;
  'provinceId': number;
  'cityId': number;
  'districtId': number;
  'detailAddress': string;
  'mobilePhone': string;
  'expressCompany': string;
  'courierId': string;
  disabled: boolean;
}

const data = observable<Partial<PersonItem>>({ disabled: true });
const addressData = observable<{
  provinceData: ValueAndLabelData;
  cityData: ValueAndLabelData;
  districtData: ValueAndLabelData;
}>({
  provinceData: [],
  cityData: [],
  districtData: [],
});
const formRef = React.createRef<FormInstance>();

@observer
class ReceivePerson extends React.Component {
  @observable public isLoading = false;

  @action public handleFinish = (values: PersonItem) => {
    this.isLoading = true;
    request({
      url: '/api/cloud/wms/rest/recipientInfo/update',
      method: 'post',
      data: {
        recipientName: _.toString(values.recipientName),
        mobilePhone: _.toString(values.mobilePhone),
        detailAddress: _.toString(values.detailAddress) || null,
        provinceId: _.toString(values.provinceId) || null,
        cityId: _.toString(values.cityId) || null,
        districtId: _.toString(values.districtId) || null,
        sourceId: values.sourceId,
        sourceType: 1,
        id: values.id,
      },
    })
      .then(() => {
        message.success({
          key: '保存成功',
          content: '保存成功',
        });
      })
      .finally(() => this.isLoading = false);
  };

  render() {
    return (
      <Form
        className={styles.receivePersonContainer}
        labelCol={{ span: 8 }}
        layout="horizontal"
        onFinish={this.handleFinish}
        ref={formRef}
        wrapperCol={{ span: 16 }}
      >
        <Form.Item
          label="sourceId"
          name="sourceId"
          rules={[{ required: true }]}
          style={{ display: 'none' }}
        >
          <Input type="hidden"/>
        </Form.Item>
        <Form.Item
          label="id"
          name="id"
          rules={[{ required: true }]}
          style={{ display: 'none' }}
        >
          <Input type="hidden"/>
        </Form.Item>
        <Form.Item
          label="sourceType"
          name="sourceType"
          rules={[{ required: true }]}
          style={{ display: 'none' }}
        >
          <Input type="hidden"/>
        </Form.Item>
        <Row style={{ width: '100%' }}>
          <Col span={6}>
            <Form.Item
              label="收件人"
              name="recipientName"
            >
              <Input
                allowClear
                autoComplete="off"
                disabled={data.disabled}
                placeholder="请输入收件人"
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="收件人手机"
              name="mobilePhone"
              rules={[
                {
                  required: false,
                  message: '请输入11位手机号',
                  len: 11,
                },
              ]}
            >
              <Input
                allowClear
                autoComplete="off"
                disabled={data.disabled}
                placeholder="收件人手机"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ width: '100%' }}>
          <Col span={6}>
            <Form.Item
              label="省"
              name="provinceId"
            >
              <Select
                allowClear
                disabled={data.disabled}
                onChange={(provinceId: string) => {
                  set(addressData, {
                    cityData: [],
                    districtData: [],
                  });
                  formRef.current.setFieldsValue({
                    cityId: null,
                    districtId: null,
                  });

                  if (provinceId != null) {
                    getAddress(provinceId, 1)
                      .then((cityData) => {
                        set(addressData, { cityData });
                      });
                  }
                }}
                optionFilterProp="label"
                options={addressData.provinceData}
                placeholder="请选择省"
                showSearch
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="市"
              name="cityId"
            >
              <Select
                allowClear
                disabled={data.disabled}
                onChange={(cityId: string) => {
                  set(addressData, { districtData: []});
                  formRef.current.setFieldsValue({ districtId: null });
                  if (cityId != null) {
                    getAddress(cityId, 2)
                      .then((districtData) => {
                        set(addressData, { districtData });
                      });
                  }
                }}
                optionFilterProp="label"
                options={addressData.cityData}
                placeholder="请选择市"
                showSearch
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="区"
              name="districtId"
            >
              <Select
                allowClear
                disabled={data.disabled}
                optionFilterProp="label"
                options={addressData.districtData}
                placeholder="请选择区"
                showSearch
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ width: '100%' }}>
          <Col span={24}>
            <Form.Item
              label="详细地址"
              labelCol={{ span: 2 }}
              name="detailAddress"
              wrapperCol={{ span: 16 }}
            >
              <Input
                allowClear
                autoComplete="off"
                disabled={data.disabled}
                placeholder="请输入详细地址"
              />
            </Form.Item>
          </Col>
        </Row>
        <Space>
          <Button type="primary">
            复制
          </Button>
          <Button
            htmlType="submit"
            loading={this.isLoading}
            type="primary"
          >
            确定
          </Button>
        </Space>
      </Form>
    );
  }
}

export function receivePerson() {
  return {
    tab: {
      name: '收货人信息',
      value: 'receivePerson',
    },
    isCustom: true,
    customView: ReceivePerson,
    api: {
      onQuery: ({
        pid,
        cursorRow,
      }) => {
        if (pid) {
          return request<BaseData<PersonItem>>({
            url: '/api/cloud/wms/rest/recipientInfo/get',
            method: 'post',
            data: {
              sourceId: pid,
              sourceType: 1,
            },
            headers: { warehouseId: cursorRow?.warehouseId || '' },
          })
            .then((info) => {
              const newData = {
                recipientName: _.toString(info.data?.recipientName),
                mobilePhone: _.toString(info.data?.mobilePhone),
                provinceId: _.toString(info.data?.provinceId) || null,
                detailAddress: _.toString(info.data?.detailAddress) || null,
                cityId: _.toString(info.data?.cityId) || null,
                districtId: _.toString(info.data?.districtId) || null,
                disabled: cursorRow.approveState == 2,
                sourceId: cursorRow.id,
                sourceType: 1,
                id: info.data?.id,
              };
              set(data, newData);
              formRef.current.setFieldsValue(newData);

              // 省
              getAddress(-1, 0)
                .then((provinceData) => {
                  set(addressData, { provinceData });
                });

              // 市
              if (info.data?.provinceId != null) {
                getAddress(info.data.provinceId, 1)
                  .then((cityData) => {
                    set(addressData, { cityData });
                  });
              }

              // 区
              if (info.data?.cityId != null) {
                getAddress(info.data.cityId, 2)
                  .then((districtData) => {
                    set(addressData, { districtData });
                  });
              }
            });
        } else {
          return Promise.reject();
        }
      },
    },
  };
}
