import { message, Modal } from 'antd';
import type { BaseData } from 'egenie-common';
import { getStaticResourceUrl, destroyAllModal, getCustomPrintParam, printHelper, renderModal, request } from 'egenie-common';
import { values } from 'mobx';
import React from 'react';
import { ImportFile } from '../../utils';
import type { StockMainItem } from '../stockIn/types';
import type { Store } from './index';
import { ParamModal } from './paramModal';

export function mainTableButton(context: Store) {
  return [
    {
      permissionId: '1',
      text: '新建',
      icon: 'icon-add',
      handleClick: () => {
        context.openAddModal();
      },
    },
    {
      permissionId: '21',
      text: '审核',
      icon: 'icon-audit',
      handleClick: () => {
        const selectRows: StockMainItem[] = context.gridModel.gridModel.selectRows;
        if (!selectRows.length) {
          const error = '请选择数据';
          message.warning({
            key: error,
            content: error,
          });
          return;
        }

        if (selectRows.some((item) => item.approveState == 2)) {
          const error = '存在已经审核的数据';
          message.warning({
            key: error,
            content: error,
          });
          return;
        }

        if (selectRows.some((item) => item.wmsPickingOrderNo)) {
          const error = '存在已生成波次的数据';
          message.warning({
            key: error,
            content: error,
          });
          return;
        }

        Modal.confirm({
          content: '确认审核吗?',
          onOk: () => request({
            url: '/api/cloud/wms/rest/bill/other/out/approve',
            method: 'put',
            params: {
              otherStockOrderIds: selectRows.map((item) => item.id)
                .join(','),
            },
          })
            .then(() => {
              context.programme.gridModel.onRefresh();
              message.success('审核成功');
            }),
        });
      },
    },
    {
      permissionId: '45',
      text: '删除',
      icon: 'icon-delete',
      handleClick: () => {
        const selectRows: StockMainItem[] = context.gridModel.gridModel.selectRows;
        if (!selectRows.length) {
          const error = '请选择数据';
          message.warning({
            key: error,
            content: error,
          });
          return;
        }

        if (!selectRows.every((item) => item.approveState == 1)) {
          const error = '只能删除未审核的数据';
          message.warning({
            key: error,
            content: error,
          });
          return;
        }

        if (selectRows.some((item) => item.wmsPickingOrderNo)) {
          const error = '存在已生成波次的数据';
          message.warning({
            key: error,
            content: error,
          });
          return;
        }

        Modal.confirm({
          content: '确认删除吗?',
          onOk: () => request({
            url: '/api/cloud/wms/rest/bill/other/delete',
            method: 'post',
            data: {
              otherStockOrderIds: selectRows.map((item) => item.id)
                .join(','),
            },
          })
            .then(() => {
              context.programme.gridModel.onQuery();
              message.success('删除成功');
            }),
        });
      },
    },
    {
      permissionId: '30',
      text: '创建波次',
      icon: 'icon-audit',
      handleClick: () => {
        const selectRows: StockMainItem[] = context.gridModel.gridModel.selectRows;
        if (!selectRows.length) {
          const error = '请至少选择一条数据';
          message.warning({
            key: error,
            content: error,
          });
          return;
        }

        if (selectRows.length > 200) {
          const error = '数据超过200条';
          message.warning({
            key: error,
            content: error,
          });
          return;
        }

        if (!selectRows.every((item) => item.approveState == 1)) {
          const error = '未审核的数据才能生成';
          message.warning({
            key: error,
            content: error,
          });
          return;
        }

        renderModal(
          <ParamModal
            callback={((params) => {
              return request({
                url: '/api/cloud/wms/rest/wave/other/stock/wave/create',
                method: 'post',
                data: {
                  ...params,
                  otherStockOrderId: selectRows.map((item) => item.id)
                    .join(','),
                },
              })
                .then(() => {
                  context.programme.gridModel.onQuery();
                  message.success('生成成功');
                  destroyAllModal();
                });
            })}
            onCancel={destroyAllModal}
          />
        );
      },
    },
    {
      permissionId: '4006',
      text: '打印出库单',
      icon: 'icon-print',
      handleClick: async() => {
        const selectRows: StockMainItem[] = context.gridModel.gridModel.selectRows;
        const ids = selectRows.map((item) => item.id).join(',');
        if (selectRows.length !== 1) {
          const error = '请选择一条主表数据';
          message.warning({
            key: error,
            content: error,
          });
          return;
        }

        const customPrintParam = await getCustomPrintParam('17');
        const printData = await request<BaseData<{ userDataList: any[]; tempData: any; }>>({
          url: '/api/cloud/print/wms/bill/other/temp',
          method: 'post',
          data: {
            tempId: customPrintParam.templateId,
            tempType: customPrintParam.tempType,
            id: ids,
          },
        });
        printHelper.toggleToLodop();
        await printHelper.print({
          contents: printData?.data?.userDataList || [],
          templateData: printData.data.tempData || {},
          preview: customPrintParam.preview,
        });
      },
    },
    {
      text: '导出',
      icon: 'icon-export',
      handleClick: () => {
        const ids = values(context.programme.gridModel.gridModel.selectedIds)
          .join(',');
        const fileName = '出库单信息';
        const exportType = 'cloud_wms_out_stock';
        const filterParams = context.programme.filterItems.params;
        const queryParam = {
          ...filterParams,
          orderType: '2',
          'order_type-4-1': '1, 3',
        };
        const queryParamShow = context.programme.filterItems.translateParams.join(' ');

        if (ids) {
          context.exportStore.onShow(fileName, exportType, ids, queryParam, queryParamShow);
        } else {
          Modal.confirm({
            title: '提示',
            content: '未选择数据将导出全部数据?',
            onOk: () => {
              context.exportStore.onShow(fileName, exportType, '', queryParam, queryParamShow);
            },
          });
        }
      },
    },
    {
      permissionId: '5',
      text: '导入',
      icon: 'icon-import',
      handleClick: () => {
        renderModal(
          <ImportFile
            callback={((params) => {
              const fd = new FormData();
              for (const key in params) {
                if (Object.prototype.hasOwnProperty.call(params, key)) {
                  fd.append(key, params[key]);
                }
              }

              return request({
                url: '/api/cloud/wms/rest/excelImport/wmsOtherStockOutDetailImport',
                method: 'post',
                data: fd,
              })
                .then(() => {
                  context.gridModel.onQuery();
                  message.success('上传成功');
                  destroyAllModal();
                });
            })}
            onCancel={destroyAllModal}
            templateUrl={getStaticResourceUrl('pc/ts/egenie-cloud-wms/downloadTemplate/出库单导入模板.xlsx')}
          />
        );
      },
    },
  ];
}
