import { Form, Modal, InputNumber } from 'antd';
import type { BaseData } from 'egenie-common';
import { request } from 'egenie-common';
import React from 'react';

interface ParamsData {
  maxPickNum?: number;
}

interface ParamModalProps {
  onCancel: () => void;
  callback?: (params: ParamsData) => Promise<unknown>;
}

export function ParamModal({
  onCancel,
  callback,
}: ParamModalProps) {
  const [form] = Form.useForm();
  const [
    paramData,
    setParamData,
  ] = React.useState<{ loading: boolean; params: ParamsData; }>({
    loading: false,
    params: null,
  });
  const handleFinish = React.useCallback((params) => {
    setParamData({
      loading: true,
      params,
    });
  }, []);

  React.useEffect(() => {
    request<BaseData<number>>({ url: '/api/cloud/wms/rest/wave/other/stock/wave/get/max/pick/num' })
      .then((info) => form.setFieldsValue({ maxPickNum: info.data >>> 0 || undefined }));
  }, []);

  React.useEffect(() => {
    if (paramData.params) {
      callback(paramData.params)
        .catch(() => setParamData({
          loading: false,
          params: null,
        }));
    }
  }, [
    callback,
    paramData,
  ]);

  return (
    <Modal
      centered
      maskClosable={false}
      okButtonProps={{ loading: paramData.loading }}
      onCancel={() => onCancel()}
      onOk={() => form.submit()}
      title="创建波次"
      visible
      width={400}
    >
      <Form
        form={form}
        labelCol={{ span: 8 }}
        layout="horizontal"
        onFinish={handleFinish}
        wrapperCol={{ span: 16 }}
      >
        <Form.Item
          label="每个波次拣货数量"
          name="maxPickNum"
          rules={[
            {
              required: true,
              message: '请输入数量',
            },
            {
              type: 'number',
              min: 1,
              message: '最小为1',
            },
          ]}
        >
          <InputNumber
            max={999999}
            min={0}
            placeholder="请输入数量"
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
