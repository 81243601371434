import { InputNumber, message } from 'antd';
import { multiple, toFixed } from 'egenie-common';
import type { PaginationData } from 'egenie-utils';
import { request, ImgFormatter } from 'egenie-utils';
import { Observer } from 'mobx-react';
import React from 'react';
import type { StockDetailItem } from '../stockIn/types';
import type { Store } from './index';

export function stockOutDetailGrid(context: Store) {
  return {
    filterItems: [
      {
        field: 'skuNo',
        label: 'SKU编码',
        type: 'input',
      },
      {
        field: 'vendorNo',
        label: '供应商编码',
        type: 'input',
      },
    ],
    tab: {
      name: '出库单详情',
      value: 'detail',
    },
    grid: {
      sumColumns: [
        'number',
        'sum',
      ],
      columns: [
        {
          name: '库区',
          key: 'warehouseAreaName',
          width: 150,
        },
        {
          name: '库位',
          key: 'warehouseBinName',
          width: 150,
        },
        {
          name: '货主',
          key: 'ownerName',
          width: 150,
        },
        {
          key: 'pic',
          name: '图片',
          width: 50,
          formatter: ({ row }) => <ImgFormatter value={row.pic}/>,
        },
        {
          name: 'SKU编码',
          key: 'skuNo',
          width: 150,
        },
        {
          name: '商品编码',
          key: 'productNo',
          width: 150,
        },
        {
          name: '供应商',
          key: 'vendorNo',
          width: 150,
        },
        {
          name: '计划数量',
          key: 'planNumber',
          width: 100,
          formatter: ({ row }: { row: StockDetailItem; }) => {
            const subGrid = context.programme.gridModel.subTablesModel.listModel[0].gridModel;
            const disabledInput = [
              row.otherStockOrderType === 33,
              row.otherStockOrderType === 30,
              row.otherStockOrderType === 6 && row.sourceType === 19,
            ].some(Boolean);
            return (
              <Observer>
                {
                  () => (
                    <InputNumber
                      disabled={disabledInput}
                      max={99999}
                      min={0}
                      onBlur={() => {
                        request({
                          method: 'post',
                          url: '/api/cloud/wms/rest/bill/other/detail/erp/update',
                          data: {
                            planNumber: subGrid.rows.find((item) => item.id === row.id)?.planNumber,
                            id: row.id,
                          },
                        })
                          .then(() => {
                            message.success('保存成功');
                            context.programme.gridModel.subTablesModel.listModel[0].onRefresh();
                          });
                      }}
                      onChange={(value) => {
                        subGrid.rows.find((item) => item.id === row.id).planNumber = value;
                      }}
                      onClick={(event) => event.stopPropagation()}
                      size="small"
                      style={{ width: '100%' }}
                      value={subGrid.rows.find((item) => item.id === row.id)?.planNumber}
                    />
                  )
                }
              </Observer>
            );
          },
        },
        {
          name: '拣货数量',
          key: 'pickedNumber',
          width: 80,
        },
        {
          name: '实际数量',
          key: 'actualNumber',
          width: 80,
        },
        {
          name: '商品名称',
          key: 'productName',
          width: 150,
        },
        {
          name: '颜色',
          key: 'color',
          width: 100,
        },
        {
          name: '尺码',
          key: 'size',
          width: 100,
        },
        {
          name: '规格',
          key: 'spec',
          width: 100,
        },
        {
          name: '条形码',
          key: 'barCode',
          width: 150,
        },
        {
          name: '成本价',
          key: 'costPrice',
          width: 80,
          formatter: ({ row }: { row: StockDetailItem; }) => (
            <span>
              {toFixed(row.costPrice, 2)}
            </span>
          ),
        },
        {
          name: '金额',
          key: 'sum',
          width: 80,
          formatter: ({ row }: { row: StockDetailItem; }) => (
            <span>
              {toFixed(row.sum, 2)}
            </span>
          ),
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      setColumnsDisplay: true,
      gridIdForColumnConfig: '/egenie-cloud-wms/stockOut/index/2',
      rows: [],
      pageSize: 50,
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: true,
    },
    api: {
      onQuery: ({
        data,
        pid,
        cursorRow,
      }) => {
        const {
          cond,
          sidx,
          sord,
          page,
          pageSize,
        } = data;
        return request<PaginationData<StockDetailItem>>({
          url: '/api/cloud/wms/rest/bill/other/detail/page',
          method: 'POST',
          data: {
            ...cond,
            wmsOtherStockOrderId: pid,
            sidx,
            sord,
            page,
            pageSize,
          },
          headers: { warehouseId: cursorRow?.warehouseId || '' },
        })
          .then((info) => ({
            ...info,
            data: {
              ...info.data,
              list: (info?.data?.list || []).map((item) => ({
                ...item,
                sum: multiple(item.number, item.costPrice),
              })),
            },
          }));
      },
    },
  };
}
