import type { FilterItemOptions } from 'egenie-utils';
import type { Store } from './index';

export function stockOutFilterItems(context: Store): FilterItemOptions[] {
  return [
    {
      type: 'date',
      field: 'date',
      label: '日期类型',
      selectValue: '1',
      startParamsField: 'startTime',
      endParamsField: 'endTime',
      data: [
        {
          value: '3',
          label: '制单日期',
        },
        {
          value: '1',
          label: '创建时间',
        },
        {
          value: '4',
          label: '打包时间',
        },
      ],
    },
    {
      field: 'warehouseId',
      type: 'select',
      label: '仓库',
      allowClear: false,
      onChangeCallback: (warehouseIds: string) => {
        context.getDataAfterWarehouse(warehouseIds);
        context.programme.filterItems.updateFilterItem([
          {
            field: 'ownerId',
            value: undefined,
          },
        ]);
      },
    },
    {
      field: 'ownerId',
      type: 'select',
      label: '货主',
      mode: 'multiple',
    },
    {
      type: 'select',
      field: 'vendorId',
      label: '供应商',
      mode: 'multiple',
      maxItemsLength: 500,
    },
    {
      field: 'approveState',
      type: 'select',
      label: '审核状态',
    },
    {
      field: 'otherStockOrderState',
      type: 'select',
      mode: 'multiple',
      label: '单据状态',
    },
    {
      field: 'otherStockOrderType',
      type: 'select',
      mode: 'multiple',
      label: '单据类型',
    },
    {
      field: 'wmsOtherStockOrderNo',
      type: 'input',
      label: '单据编号',
    },
    {
      field: 'barCode',
      type: 'input',
      label: '条形码',
    },
    {
      field: 'productNo',
      type: 'input',
      label: '商品编码',
    },
    {
      field: 'skuNo',
      type: 'input',
      label: 'SKU编码',
    },
    {
      field: 'note',
      type: 'input',
      label: '备注',
    },
    {
      field: 'sourceNo',
      type: 'input',
      label: '来源单据号',
    },
    {
      field: 'wmsPickingOrderNo',
      type: 'input',
      label: '波次号',
    },
    {
      field: 'refoundState',
      type: 'select',
      label: '退货单状态',
      data: [
        {
          value: '1',
          label: '已生成',
        },
        {
          value: '0',
          label: '未生成',
        },
      ],
    },
  ];
}
