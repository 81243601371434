import { Button, Col, DatePicker, Form, Input, Modal, Row, Select } from 'antd';
import type { ValueAndLabelData } from 'egenie-utils';
import { MainSubStructure } from 'egenie-utils';
import _ from 'lodash';
import { action, computed, observable } from 'mobx';
import { inject, observer, Provider } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import type { VendorItem } from '../../../utils';
import type { StockMainItem } from '../../stockIn/types';
import { skuCondition } from '../../stockIn/utils';
import { AddGoodsModal } from '../addGoods/addGoodsModal';
import { AddAndEditStore } from './addAndEditStore';

@inject('addAndEditStore')
@observer
class FormWrapper extends React.Component<{ addAndEditStore?: AddAndEditStore; otherStockOrderType: ValueAndLabelData; }> {
  @observable public vendorSearch = '';

  @action public onVendorSearch = (vendorSearch: string) => {
    this.vendorSearch = typeof vendorSearch === 'string' ? vendorSearch : '';
  };

  @computed
  public get vendorIdData(): VendorItem[] {
    return this.props.addAndEditStore.vendorIdData.filter(
      (item) => _.toString(item.label)
        .toLowerCase()
        .includes(this.vendorSearch.toLowerCase()))
      .slice(0, 500);
  }

  render() {
    const {
      vendorSearch,
      onVendorSearch,
      vendorIdData,
    } = this;
    const {
      addAndEditStore: {
        formRef,
        warehouseData,
        handleWarehouseChange,
        warehouseAreaData,
        getUnusedWarehouseBin,
        ownerData,
        scanAddProduct,
        openAddGoodsModal,
        handleOtherStockOrderTypeChange,
        warehouseIdDisabled,
        warehouseAreaIdDisabled,
        ownerIdDisabled,
        vendorIdDisabled,
        otherStockOrderTypeDisabled,
        showVendorId,
        handleVendorIdChange,
      },
      otherStockOrderType,
    } = this.props;
    const initialValues = {
      scanKey: skuCondition.skuNo.value,
      otherStockOrderDate: moment(Date.now()),
    };
    const span = showVendorId ? 6 : 8;

    return (
      <Form
        initialValues={initialValues}
        labelCol={{ span: 8 }}
        layout="horizontal"
        ref={formRef}
        wrapperCol={{ span: 16 }}
      >
        <>
          <Row>
            <Col span={span}>
              <Form.Item
                label="仓库"
                name="warehouseId"
                rules={[
                  {
                    required: true,
                    message: '请选择仓库',
                  },
                ]}
              >
                <Select
                  disabled={warehouseIdDisabled}
                  onChange={handleWarehouseChange}
                  options={warehouseData}
                  placeholder="请选择仓库"
                />
              </Form.Item>
            </Col>
            <Col span={span}>
              <Form.Item
                label="库区"
                name="warehouseAreaId"
                rules={[
                  {
                    required: true,
                    message: '请选择库区',
                  },
                ]}
              >
                <Select
                  disabled={warehouseAreaIdDisabled}
                  onChange={() => getUnusedWarehouseBin(true)}
                  options={warehouseAreaData}
                  placeholder="请选择库区"
                />
              </Form.Item>
            </Col>
            <Col span={span}>
              <Form.Item
                label="货主"
                name="ownerId"
                rules={[
                  {
                    required: true,
                    message: '请选择货主',
                  },
                ]}
              >
                <Select
                  disabled={ownerIdDisabled}
                  onChange={() => getUnusedWarehouseBin(true)}
                  optionFilterProp="label"
                  options={ownerData}
                  placeholder="请选择货主"
                  showSearch
                />
              </Form.Item>
            </Col>
            {
              showVendorId ? (
                <Col span={6}>
                  <Form.Item
                    label="供应商"
                    name="vendorId"
                    rules={[
                      {
                        required: true,
                        message: '请选择供应商',
                      },
                    ]}
                  >
                    <Select
                      disabled={vendorIdDisabled}
                      onChange={handleVendorIdChange}
                      onSearch={onVendorSearch}
                      optionFilterProp="label"
                      options={vendorIdData}
                      placeholder="请选择供应商"
                      searchValue={vendorSearch}
                      showSearch
                    />
                  </Form.Item>
                </Col>
              ) : null
            }
          </Row>
          <Row>
            <Col span={8}>
              <Form.Item
                label="单据类型"
                name="otherStockOrderType"
                rules={[
                  {
                    required: true,
                    message: '请选择单据类型',
                  },
                ]}
              >
                <Select
                  disabled={otherStockOrderTypeDisabled}
                  onChange={handleOtherStockOrderTypeChange}
                  options={otherStockOrderType}
                  placeholder="请选择单据类型"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="制单日期"
                name="otherStockOrderDate"
                rules={[
                  {
                    required: true,
                    message: '请选择制单日期',
                  },
                ]}
              >
                <DatePicker
                  placeholder="请选择制单日期"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="备注"
                name="note"
              >
                <Input placeholder="请输入备注"/>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Form.Item
                label="选择商品"
                name="scanKey"
              >
                <Select
                  options={[
                    skuCondition.skuNo,
                    skuCondition.productNo,
                  ]}
                  placeholder="选择商品"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="scanValue"
                wrapperCol={{
                  span: 16,
                  offset: 8,
                }}
              >
                <Input
                  autoComplete="off"
                  onPressEnter={(event: any) => scanAddProduct(event.target.value.trim())}
                  placeholder="按Enter录入"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item wrapperCol={{
                span: 16,
                offset: 8,
              }}
              >
                <Button
                  block
                  onClick={openAddGoodsModal}
                  type="primary"
                >
                  批量添加
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </>
      </Form>
    );
  }
}

@inject('addAndEditStore')
@observer
class ModalWrapper extends React.Component<{ addAndEditStore?: AddAndEditStore; }> {
  render() {
    const {
      formRef,
      showAddGoodsModal,
      closeAddGoodsModal,
      addGoodsModalCallback,
    } = this.props.addAndEditStore;

    if (showAddGoodsModal) {
      return (
        <AddGoodsModal
          callback={addGoodsModalCallback}
          extraParams={{
            otherStockOrderType: formRef.current?.getFieldValue('otherStockOrderType'),
            ownerId: formRef.current?.getFieldValue('ownerId'),
            warehouseId: formRef.current?.getFieldValue('warehouseId'),
            warehouseAreaId: formRef.current?.getFieldValue('warehouseAreaId'),
            vendorId: formRef.current?.getFieldValue('vendorId'),
          }}
          onCancel={closeAddGoodsModal}
        />
      );
    } else {
      return null;
    }
  }
}

@observer
export class AddAndEditModal extends React.Component<{
  title: string;
  otherStockOrderType: ValueAndLabelData;
  vendorIdData: VendorItem[];
  mainItem?: StockMainItem;
  onCancel: () => void;
  callback: () => void;
}> {
  constructor(props) {
    super(props);
    this.addAndEditStore = new AddAndEditStore(props.vendorIdData, props.callback, props.mainItem);
  }

  componentDidMount() {
    this.addAndEditStore.init();
  }

  public addAndEditStore: AddAndEditStore;

  render() {
    const {
      title,
      otherStockOrderType,
      onCancel,
    } = this.props;
    const {
      loading,
      mainSubStructureModel,
      handleSubmit,
    } = this.addAndEditStore;
    return (
      <Provider addAndEditStore={this.addAndEditStore}>
        <Modal
          centered
          maskClosable={false}
          okButtonProps={{ loading }}
          okText="保存"
          onCancel={() => onCancel()}
          onOk={handleSubmit}
          title={title}
          visible
          width={1100}
        >
          <FormWrapper

            otherStockOrderType={otherStockOrderType}
          />

          <div style={{ height: 400 }}>
            <MainSubStructure store={mainSubStructureModel}/>
          </div>
        </Modal>
        <ModalWrapper/>
      </Provider>
    );
  }
}

