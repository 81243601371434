import _ from 'lodash';

export enum STOCK_IN_ORDER_TYPE {
  vendorStockIn = 34,
  consignorStockIn = 38,
  selfStockIn = 39,
}

export enum STOCK_OUT_ORDER_TYPE {
  vendorStockOut = 7,
  consignorStockOut = 39,
}

export const skuCondition = {
  skuNo: {
    value: 's#sku_no',
    label: 'SKU编码',
    paramsKey: 'skuNo',
  },
  barCode: {
    value: 's#bar_code',
    label: 'SKU条码',
    paramsKey: 'barCode',
  },
  productNo: {
    value: 'p#product_no',
    label: '商品编码',
    paramsKey: 'productNo',
  },
};

export function onlyId(item: { skuId?: string; warehouseBinId?: string; }): string {
  return `${_.toString(item.skuId)}_${_.toString(item.warehouseBinId)}`;
}

export const BOOLMap = {
  '1': true,
  '0': false,
};
