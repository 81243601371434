import type { BaseData, DictData, PaginationData } from 'egenie-common';
import { request, objToDict } from 'egenie-common';
import { MainSubStructureModel, Programme, ProgrammeComponent, ExportStore, ExportModal } from 'egenie-utils';
import { action, observable } from 'mobx';
import { inject, observer, Provider } from 'mobx-react';
import React from 'react';
import type { VendorItem } from '../../utils';
import { formatWarehouseChoice, getAllVendor, getOwner, getWarehouse, logSubGrid } from '../../utils';
import type { StockMainItem } from '../stockIn/types';
import { AddAndEditModal } from './addAndEdit/addAndEditModal';
import { mainTableButton } from './mainTableButton';
import { mainTableColumns } from './mainTableColumns';
import { receivePerson } from './receivePerson';
import { stockOutDetailDeliver } from './stockOutDetailDeliver';
import { stockOutDetailGrid } from './stockOutDetailGrid';
import { stockOutFilterItems } from './stockOutFilterItems';

export class Store {
  constructor() {
    // 仓库
    getWarehouse()
      .then((info) => {
        this.programme.filterItems.updateFilterItem([
          {
            field: 'warehouseId',
            data: info,
            value: formatWarehouseChoice(info, 'warehouseId'),
          },
        ]);
        this.getDataAfterWarehouse(formatWarehouseChoice(info, 'warehouseId'));
      });

    // 货主
    getOwner()
      .then((info) => this.programme.filterItems.addDict({ ownerId: info }));

    // 供应商
    getAllVendor()
      .then((info) => this.programme.filterItems.addDict({ vendorId: info }));

    // 单据状态
    request<DictData>({ url: '/api/cloud/wms/rest/bill/other/out/stock/order/state' })
      .then((info) => this.programme.filterItems.addDict({ otherStockOrderState: objToDict(info) }));

    // 单据类型
    request<DictData>({ url: '/api/cloud/wms/rest/bill/other/out/other/stock/order/type' })
      .then((info) => this.programme.filterItems.addDict({ otherStockOrderType: objToDict(info) }));

    // 审核状态
    request<DictData>({ url: '/api/cloud/wms/rest/bill/other/approve/state' })
      .then((info) => this.programme.filterItems.addDict({ approveState: objToDict(info) }));

    // 快递公司
    request<BaseData<Array<{ courierId: string; courierName: string; }>>>({
      method: 'post',
      url: '/api/cloud/wms/rest/recipientInfo/courier',
    })
      .then((info) => this.programme.filterItems.addDict({
        courier: (info.data || []).map((item) => ({
          value: `${item.courierId}`,
          label: item.courierName,
        })),
      }));
  }

  public getDataAfterWarehouse = (warehouseId: string): void => {
    // 货主
    getOwner({ warehouseId })
      .then((info) => this.programme.filterItems.addDict({ ownerId: info }));
  };

  public gridModel = new MainSubStructureModel({
    buttons: mainTableButton(this),
    pageId: '60013',
    grid: {
      // @ts-ignore
      columns: mainTableColumns(this),
      rows: [],
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: true,
      pageSize: 50,
      setColumnsDisplay: true,
      gridIdForColumnConfig: '/egenie-cloud-wms/stockOut/index/1',
      sumColumns: [
        'totalNumber',
        'totalPrice',
      ],
    },

    // 主表查询api
    api: {
      onQuery: (params) => {
        const {
          filterParams = {},
          ...rest
        } = params;
        return request<PaginationData<StockMainItem>>({
          url: '/api/cloud/wms/rest/bill/other/page',
          data: {
            ...rest,
            orderType: '2',
            'order_type-4-1': '1, 3',
            ...filterParams,
          },
          method: 'POST',
          headers: { warehouseId: filterParams?.warehouseId || '' },
        });
      },
    },
    subTables: {
      activeTab: 'detail',
      tabsFlag: {
        inited: {
          detail: true,
          log: false,
        },
        searched: {},
      },
      list: [
        // @ts-ignore
        stockOutDetailGrid(this),
        logSubGrid(230000, {}, 'warehouseId'),
        receivePerson(),
        stockOutDetailDeliver(),
      ],
    },
  });

  public programme = new Programme({
    gridModel: this.gridModel,
    filterItems: stockOutFilterItems(this),
    moduleName: 'egenieCloudWmsStockOutIndex',
  });

  @observable public showAddModal = false;

  @action public openAddModal = () => {
    this.showAddModal = true;
  };

  @action public closeAddModal = () => {
    this.showAddModal = false;
  };

  @action public addModalCallback = () => {
    this.closeAddModal();
    this.programme.gridModel.onRefresh();
  };

  @observable public showEditModal = false;

  @observable public mainItem: StockMainItem = null;

  @action public openEditModal = (mainItem: StockMainItem) => {
    this.mainItem = mainItem;
    this.showEditModal = true;
  };

  @action public closeEditModal = () => {
    this.mainItem = null;
    this.showEditModal = false;
  };

  @action public editModalCallback = () => {
    this.closeEditModal();
    this.programme.gridModel.onRefresh();
  };

  public exportStore: ExportStore = new ExportStore({ parent: null });
}

@inject('store')
@observer
class ModalWrapper extends React.Component<{ store?: Store; }> {
  render() {
    const {
      showEditModal,
      closeEditModal,
      editModalCallback,
      mainItem,
      showAddModal,
      closeAddModal,
      addModalCallback,
      programme,
    } = this.props.store;

    const vendorIdData = (programme.filterItems.dict.vendorId || []) as VendorItem[];
    const otherStockOrderType = this.props.store.programme.filterItems.dict.otherStockOrderType || [];

    return (
      <>
        {
          showEditModal ? (
            <AddAndEditModal
              callback={editModalCallback}
              mainItem={mainItem}
              onCancel={closeEditModal}
              otherStockOrderType={otherStockOrderType}
              title="编辑其它出库单"
              vendorIdData={vendorIdData}
            />
          ) : null
        }
        {
          showAddModal ? (
            <AddAndEditModal
              callback={addModalCallback}
              onCancel={closeAddModal}
              otherStockOrderType={otherStockOrderType}
              title="新建其它出库单"
              vendorIdData={vendorIdData}
            />
          ) : null
        }
      </>
    );
  }
}

export default class extends React.Component {
  public store = new Store();

  render() {
    return (
      <Provider store={this.store}>
        <ProgrammeComponent store={this.store.programme}/>
        <ExportModal store={this.store.exportStore}/>
        <ModalWrapper/>
      </Provider>
    );
  }
}
