import { Button, Input, Modal, Select } from 'antd';
import { EgGrid } from 'egenie-utils';
import { inject, observer, Provider } from 'mobx-react';
import React from 'react';
import type { StockDetailItem } from '../../stockIn/types';
import { skuCondition } from '../../stockIn/utils';
import styles from './addGoodsModal.less';
import { Store } from './addGoodsStore';

export interface AddGoodsModalProps {
  extraParams: Store['extraParams'];
  callback: (rows: StockDetailItem[]) => void;
  onCancel: () => void;
}

export class AddGoodsModal extends React.Component<AddGoodsModalProps> {
  constructor(props: AddGoodsModalProps) {
    super(props);
    this.store = new Store(props.callback, props.extraParams);
  }

  public store: Store;

  render() {
    const { store, props: { onCancel }} = this;
    return (
      <Provider store={store}>
        <Modal
          centered
          className={styles.container}
          closable
          maskClosable={false}
          onCancel={onCancel}
          onOk={store.handleSave}
          style={{ height: 740 }}
          visible
          width={1100}
        >
          <ModalHeader/>
          <div style={{ height: 300 }}>
            <EgGrid store={store.goodsStore.gridModel}/>
          </div>
          <div style={{ height: 40 }}>
            <Button
              className="ghost-bg-btn"
              onClick={() => {
                store.handleDelete(store.tmpStore.gridModel.selectRows);
              }}
              type="primary"
            >
              批量删除
            </Button>
          </div>
          <div style={{ height: 300 }}>
            <EgGrid store={store.tmpStore.gridModel}/>
          </div>
        </Modal>
      </Provider>
    );
  }
}

@inject('store')
@observer
class ModalHeader extends React.Component<{ store?: Store; }> {
  render() {
    const {
      selectValue,
      inputValue,
      handleInputValueChange,
      handleSelectValueChange,
      goodsStore,
      handleAdd,
    } = this.props.store;
    return (
      <div className={styles.modelHeader}>
        <section className={styles.left}>
          <Select
            bordered={false}
            onChange={handleSelectValueChange}
            options={Object.values(skuCondition)}
            value={selectValue}
          />
          <Input
            allowClear
            onChange={(event) => handleInputValueChange(event.target.value)}
            onPressEnter={() => goodsStore.gridModel.onQuery()}
            value={inputValue}
          />
          &nbsp;&nbsp;
          <Button
            onClick={() => goodsStore.gridModel.onQuery()}
            type="primary"
          >
            查询
          </Button>
          &nbsp;&nbsp;
          <Button
            className="ghost-bg-btn"
            onClick={() => handleAdd(goodsStore.gridModel.selectRows)}
          >
            批量添加
          </Button>
        </section>
      </div>
    );
  }
}
